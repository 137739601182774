import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";

import "nprogress/nprogress.css";
import App from "src/App";
import { SidebarProvider } from "src/contexts/SidebarContext";
import * as serviceWorker from "src/serviceWorker";

import { Provider } from "react-redux";
import { store } from "./store";

import { ApplicationProvider } from "./components/_shared/application-context/application-context";
import "./i18n";

ReactDOM.render(
  <Provider store={store}>
    <ApplicationProvider>
      <HelmetProvider>
        <SidebarProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </SidebarProvider>
      </HelmetProvider>
    </ApplicationProvider>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
