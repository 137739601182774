import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  fetchBaseQuery,
} from '@reduxjs/toolkit/dist/query';
import { IAuthTokens, UserResponse } from 'src/api/auth';
import { logout } from 'src/store/auth/authSlice';

const BASE_URL_API = process.env.REACT_APP_BASE_URL;

export const baseQueryWithReAuth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  any,
  FetchBaseQueryMeta
> = async (args, api, extraOptions) => {
  const baseQuery = fetchBaseQuery({
    baseUrl: BASE_URL_API,
    prepareHeaders: (headers) => {
      const USER_DATA: UserResponse = JSON.parse(
        localStorage.getItem('___reduxState__auth')
      );

      if (USER_DATA?.auth?.accessToken) {
        headers.set('x-access-token', `${USER_DATA.auth.accessToken}`);
        headers.set('Content-Type', 'application/json');
      }

      headers.set('integer-organization-id', localStorage.getItem('user_selected_organization_id'));
      headers.set('integer-logistic-center-id', localStorage.getItem('user_selected_logistic_center_id'));

      return headers;
    },
  });

  let result = await baseQuery(args, api, extraOptions);

  const USER_DATA: UserResponse = JSON.parse(
    localStorage.getItem('___reduxState__auth')
  );

  if (!USER_DATA) api.dispatch(logout());

  if (result.error && result.error.status === 401) {
    const { refreshToken, accessToken } = USER_DATA.auth as IAuthTokens;

    const refreshResult = await baseQuery(
      {
        url: 'api/auth/refresh-token',
        method: 'POST',
        body: {
          refreshToken,
          accessToken,
        },
      },
      api,
      extraOptions
    );
    if (refreshResult.data) {
      const authData: IAuthTokens = refreshResult.data as IAuthTokens;
      localStorage.removeItem('___reduxState__auth');

      localStorage.setItem(
        '___reduxState__auth',
        JSON.stringify({
          ...USER_DATA,
          auth: authData,
        })
      );

      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logout());
    }
  }

  return result;
};
