import { createContext, useContext } from "react";
import { UserProvider } from "./user-context/user-context";

const ApplicationContext = createContext({});

export const ApplicationProvider = ({ children }) => {
  return (
    <ApplicationContext.Provider value={{}}>
      <UserProvider>{children}</UserProvider>
    </ApplicationContext.Provider>
  );
};

export const useApplicationProvider = () => useContext(ApplicationContext);
