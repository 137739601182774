import ArchiveIcon from '@mui/icons-material/Archive';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HomeIcon from '@mui/icons-material/Home';
import {
	Box,
	Button,
	Collapse,
	List,
	ListItem,
	alpha,
	styled,
} from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink as RouterLink } from 'react-router-dom';
import { useUserContext } from 'src/components/_shared/application-context/user-context/user-context';
import { SidebarContext } from 'src/contexts/SidebarContext';

const MenuWrapper = styled(Box)(
	({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.trueWhite[50]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`,
);

const SubMenuWrapper = styled(Box)(
	({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }

        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[30]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }

          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
            background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};
            color: ${theme.colors.alpha.trueWhite[100]};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
									'transform',
									'opacity',
								])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`,
);

const SidebarSubMenu = ({ url, label, icon }) => {
	return (
		<SubMenuWrapper>
			<List component="div">
				<ListItem component="div">
					<Button
						data-testid={`sidebar-item__${label}`}
						disableRipple
						component={RouterLink}
						to={url}
						startIcon={icon}
					>
						{label}
					</Button>
				</ListItem>
			</List>
		</SubMenuWrapper>
	);
};

function SidebarMenu() {
	const {
		state: { resources },
	} = useUserContext();
	const { t } = useTranslation();
	const { closeSidebar } = useContext(SidebarContext);
	const [openAdministration, setOpenAdministration] = useState(false);

	const handleClickAdministration = () => {
		setOpenAdministration(!openAdministration);
	};

	return (
		<>
			<MenuWrapper>
				<List component="div">
					<SidebarSubMenu
						label={t('start')}
						url={'/management/administration/dashboard'}
						icon={<HomeIcon />}
					/>

					<SidebarSubMenu
						label={t('operationsWAM')}
						url={'/management/operations'}
						icon={<HomeIcon />}
					/>

					{resources?.shipping && (
						<SidebarSubMenu
							label={t('orders')}
							url={'/management/shipments'}
							icon={<ArchiveIcon />}
						/>
					)}

					{resources?.shipping && (
						<SidebarSubMenu
							label={t('receptions')}
							url={'/management/receptions'}
							icon={<ArchiveIcon />}
						/>
					)}

					{resources?.warehouse && (
						<SidebarSubMenu
							label={t('virtualWarehouse')}
							url={'/management/lots/all'}
							icon={<ArchiveIcon />}
						/>
					)}

					{resources?.waste_orders && (
						<SidebarSubMenu
							label={'Pedidos de residuo'}
							url={'/management/waste-orders'}
							icon={<ArchiveIcon />}
						/>
					)}

					{resources?.admin && (
						<>
							<SubMenuWrapper>
								<List component="div">
									<ListItem
										component="div"
										onClick={() => handleClickAdministration()}
									>
										<Button
											data-testid="administration-button"
											startIcon={<ArchiveIcon />}
										>
											{t('administration')}
											{openAdministration ? (
												<ExpandLessIcon />
											) : (
												<ExpandMoreIcon />
											)}
										</Button>
									</ListItem>
								</List>
							</SubMenuWrapper>

							<SubMenuWrapper>
								<Collapse in={openAdministration} timeout="auto" unmountOnExit>
									<List component="div">
										<ListItem component="div">
											<Button
												data-testid="administration-item__container"
												component={RouterLink}
												to="/management/administration/container"
												onClick={closeSidebar}
											>
												{t('manageBuckets')}
											</Button>
										</ListItem>
										<ListItem component="div">
											<Button
												data-testid="administration-item__logistic-center"
												component={RouterLink}
												to="/management/administration/logistic-centers"
												onClick={closeSidebar}
											>
												{t('manageLogisticsCenters')}
											</Button>
										</ListItem>
										<ListItem component="div">
											<Button
												data-testid="administration-item__scales"
												component={RouterLink}
												to="/management/administration/scales"
												onClick={closeSidebar}
											>
												{t('manageScales')}
											</Button>
										</ListItem>
									</List>
								</Collapse>
							</SubMenuWrapper>
						</>
					)}
				</List>
			</MenuWrapper>
		</>
	);
}

export default SidebarMenu;
