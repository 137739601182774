import { createSlice } from '@reduxjs/toolkit';
import { IAuthTokens, apiAuth } from 'src/api/auth';
import { User } from '../../models/users';

type AuthState = {
  user: User | null;
  auth: IAuthTokens | null;
  isAuthenticated: boolean;
  messageCreated: string | null;
};

const DEFAULT_STATE: AuthState = {
  user: null,
  auth: null,
  isAuthenticated: false,
  messageCreated: null,
};

const initialState = () => {
  const persistedState = localStorage.getItem('reduxState');
  if (persistedState) {
    return JSON.parse(persistedState);
  }
  return DEFAULT_STATE;
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem('user_selected_organization_id');
      localStorage.removeItem('user_selected_logistic_center_id');
      localStorage.removeItem('___reduxState__auth');
      state.user = null;
      state.auth = null;
      state.isAuthenticated = false;
      window.location.href = '/login';
    },
    loginSuccess: (state, action) => {
      state.user = action.payload.user;
      state.auth = action.payload.auth;
      state.isAuthenticated = true;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      apiAuth.endpoints.login.matchFulfilled,
      (state, { payload: { user, auth } }) => {
        if (user && auth) {
          state.user = user;
          state.auth = auth;
          state.isAuthenticated = true;
        }
      }
    );
    builder.addMatcher(
      apiAuth.endpoints.register.matchFulfilled,
      (state, { payload: response }) => {
        state.messageCreated = response.message;
      }
    );
  },
});

export default authSlice.reducer;
export const { logout, loginSuccess } = authSlice.actions;
