import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReAuth } from "./baseQuery";

export const apiLerCodes = createApi({
    reducerPath: 'api-ler-codes',
    baseQuery: baseQueryWithReAuth,
    endpoints: (builder) => ({
        getLerCodes: builder.mutation<any, void>({
            query: () => ({
                url: 'api/ler-codes/getAll',
                method: 'GET'
            }),
        }),
    }),
});

export const { useGetLerCodesMutation } = apiLerCodes;