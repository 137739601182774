import { logout } from "src/store/auth/authSlice"
import { useAppDispatch } from "./store"

export const useAuthActions = () => {  
    const dispatch = useAppDispatch()

    const authLogout = () => {
        dispatch(logout())
    }

    return {authLogout}
}