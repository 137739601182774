import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'src/api/baseQuery';

export interface CreateProcessRequest {
  quantity_in: number;
  quantity_out: number;
  waste_percentage: number;
  util_percentage: number;
  clasification: string;
  status_id: number;
  type_id: number;
}

export interface ProcessResponse {
  message: string;
  process: {
    quantity_in: number;
    quantity_out: number;
    waste_percentage: number;
    util_percentage: number;
    clasification: string;
    status_id: number;
    type_id: number;
    status: {
      status: string;
      description: string;
      id: number;
    };
    type: {
      type: string;
      description: string;
      id: number;
    };
    id: number;
  };
}

export interface UpdateProcessStatusRequest {
  process_id: number;
  process_status_id: number;
}

export interface ProcessRequest {
  product_id: number;
  container_ids: number[];
}

export interface FinishClassificationProcessRequest {
  process_id: number;
}

export interface AssignContainerHopperRequest {
  hopper_id: number;
  container_id: number;
}

export interface RemoveContainerHopperRequest {
  hopper_id: number;
}

export interface AssignContainerLotRequest {
  product_id: number;
  container_id: number;
  weight: number; //JSM 20240320
}

export interface CuttingRequest {
  origin_container_id: number;
  destination_container_id: number;
}

export interface ProcessByTypeRequest {
  type_id?: number;
  resource_id?: number;
  status_id?: number;
}

export interface StartCuttingProcess {
  resource_id: number,
  source_container_id: number,
  target_container_id: number,
}

export interface CloseCuttingProcess {
  process_id: number,
  sourceContainer: {
    id: number,
    location_id: number,
    weight: number,
  },
  targetContainer: {
    id: number,
    location_id: number,
    weight: number,
  }
}

export interface SackProcessRequest {
  container_id: number;
  waste_order_line_id: number;
  net_weight: number;
};

export const apiProcess = createApi({
  reducerPath: 'api_process',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    packingProcess: builder.mutation<any, ProcessRequest[]>({
      query: (body) => ({
        url: `api/process/packing-in-progress`,
        method: 'POST',
        body,
      }),
    }),
    assignContainerLot: builder.mutation<any, AssignContainerLotRequest>({
      query: (body) => ({
        url: `api/process/assign-container-lot`,
        method: 'POST',
        body,
      }),
    }),
    getAllProcessByType: builder.mutation<any, ProcessByTypeRequest>({
      query: body => ({
        url: `api/process/getAll?type_id=${body.type_id}&resource_id=${body.resource_id}&status_id=${body.status_id}`,
        method: 'GET',
      })
    }),
    startCuttingProcess: builder.mutation<any, StartCuttingProcess>({
      query: body => ({
        url: `api/process/cutting/start?resource_id=${body.resource_id}&source_container_id=${body.source_container_id}&target_container_id=${body.target_container_id}`,
        method: 'POST',
      })
    }),
    closeCuttingProcess: builder.mutation<any, CloseCuttingProcess>({
      query: body => ({
        url: `api/process/cutting/close?process_id=${body.process_id}`,
        method: 'POST',
        body: {
          sourceContainer: body.sourceContainer,
          targetContainer: body.targetContainer,
        }
      })
    }),
    changeCuttingSourceContainer: builder.mutation<any, any>({
      query: body => ({
        url: `api/process/cutting/${body.process_id}/change-source`,
        method: 'POST',
        body: {
          oldContainer: body.oldContainer,
          newContainer: body.newContainer,
        },
      })
    }),
    changeCuttingTargetContainer: builder.mutation<any, any>({
      query: body => ({
        url: `api/process/cutting/${body.process_id}/change-target`,
        method: 'POST',
        body: {
          oldContainer: body.oldContainer,
          newContainer: body.newContainer,
        },
      })
    }),
    executePackingProcess: builder.mutation<any, any>({
      query: (body) => ({
        url: `api/process/execute-packing-process`,
        method: 'POST',
        body,
      }),
    }),
    executeSackProcess: builder.mutation<any, SackProcessRequest>({
      query: (body) => ({
        url: `api/process/sack`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useGetAllProcessByTypeMutation,
  usePackingProcessMutation,
  useAssignContainerLotMutation,
  useStartCuttingProcessMutation,
  useCloseCuttingProcessMutation,
  useChangeCuttingSourceContainerMutation,
  useChangeCuttingTargetContainerMutation,
  useExecutePackingProcessMutation,
  useExecuteSackProcessMutation,
} = apiProcess;
