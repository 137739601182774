import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'src/api/baseQuery';
import { ProductCompositionCreateRequest } from './products';

interface CompositionDetailCreateRequest {
	percentage: number;
	textil_type_id: number;
}

export interface CompositionCreateRequest {
	details: CompositionDetailCreateRequest[];
}

interface ProductCreateRequest {
	owner_id?: number;
	product_type_id: number;
	color_id?: number;
	composition_id?: number;
	closed_circulation?: number;
	net_weight: number;
	composition?: ProductCompositionCreateRequest;
	comments?: string;
	waste_type_id?: number;
}

interface ContainerCreateRequest {
	reference?: string;
	location_id: number;
	product_id?: number | null;
	net_weight?: number | null;
	container_type_id: number;
	logistic_center_id?: number;
}

interface ContainerUpdateRequest {
	id: number;
	reference?: string;
	location_id?: number;
	product_id?: number | null;
	net_weight?: number | null;
	container_type_id?: number;
	logistic_center_id?: number;
}

interface InventoryCreateRequest {
	container: ContainerCreateRequest | ContainerUpdateRequest;
	product: ProductCreateRequest;
}

export const apiOrders = createApi({
	reducerPath: 'api_orders',
	baseQuery: baseQueryWithReAuth,
	endpoints: builder => ({
		createInventory: builder.mutation<any, InventoryCreateRequest>({
			query: requestBody => ({
				url: `api/orders/inventory`,
				method: 'POST',
				body: requestBody,
			}),
		}),
	}),
});

export const { useCreateInventoryMutation } = apiOrders;
