import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithReAuth } from "./baseQuery";

export const adminApi = createApi({
	reducerPath: 'admin',
	baseQuery: baseQueryWithReAuth,
	endpoints: (builder) => ({
		bulkCreateContainers: builder.mutation<any, any>({
			query: (body) => ({
				url: `api/admin/containers/bulk-create`,
				method: "POST",
				body,
			})
		})
	})
});

export const {
	useBulkCreateContainersMutation,
} = adminApi;