import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationES from "src/locales/es/translation.json"
import translationEN from "src/locales/en/translation.json"

i18n.use(initReactI18next).init({
    lng: "es",
    fallbackLng: "es",
    interpolation: {
      escapeValue: false,
    },
    resources: {
        en: {translation: translationEN},
        es: {translation: translationES},
    },
  });;

export default i18n