import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithReAuth } from "./baseQuery";

export const apiDeliveryCondition = createApi({
    reducerPath: 'api-delivery-condition',
    baseQuery: baseQueryWithReAuth,
    endpoints: (builder) => ({
        getDeliveryConditions: builder.mutation<any, void>({
            query: () => ({
                url: 'api/delivery-conditions/getAll',
                method: 'GET'
            })
        })
    })
})

export const { useGetDeliveryConditionsMutation } = apiDeliveryCondition;