import { configureStore } from "@reduxjs/toolkit";
import { adminApi } from "src/api/admin";
import { apiAuth } from "src/api/auth";
import { apiColors } from "src/api/colors";
import { apiCompositions } from "src/api/compositions";
import { apiContainers } from "src/api/containers";
import { operationsApi } from "src/api/operations";
import { apiOrders } from "src/api/orders";
import { apiUsers } from "src/api/users";
import { wasteOrdersApi } from "src/api/waste-orders";
import authReducer from "./auth/authSlice";
import compositionsReducer from "./compositions/compositionsSlice";
import userReducer from "./users/userSlice";
import { shipmentsApi } from "src/api/shipments";
import { receptionsApi } from "src/api/receptions";
import { apiQr } from "src/api/qr";
import { apiLerCodes } from "src/api/lerCodes";
import { apiDeliveryCondition } from "src/api/deliveryCondition";
import { apiProcess } from "src/api/process";

const persistanceMiddleware = (store: any) => (next: any) => (action: any) => {
  next(action);

  if (
    action.type === "api/executeMutation/fulfilled" &&
    action.payload?.action === "login"
  ) {
    localStorage.setItem(
      "___reduxState__auth",
      JSON.stringify(store.getState().auth)
    );
  } else return;
};

const middleware = (getDefaultMiddleware: any) =>
  getDefaultMiddleware().concat(
    apiAuth.middleware,
    apiUsers.middleware,
    apiOrders.middleware,
    apiCompositions.middleware,
    apiColors.middleware,
    wasteOrdersApi.middleware,
    adminApi.middleware,
    apiContainers.middleware,
    operationsApi.middleware,
    shipmentsApi.middleware,
    receptionsApi.middleware,
    apiQr.middleware,
    apiLerCodes.middleware,
    apiDeliveryCondition.middleware,
    apiProcess.middleware,
    persistanceMiddleware,
  );

export const store = configureStore({
  reducer: {
    [apiAuth.reducerPath]: apiAuth.reducer, // [api.reducerPath] is the name of the slice that we defined in api.ts
    [apiUsers.reducerPath]: apiUsers.reducer, // [api.reducerPath] is the name of the slice that we defined in api.ts
    [apiOrders.reducerPath]: apiOrders.reducer, // [api.reducerPath] is the name of the slice that we defined in api.ts
    [apiCompositions.reducerPath]: apiCompositions.reducer,
    [apiColors.reducerPath]: apiColors.reducer,
    [wasteOrdersApi.reducerPath]: wasteOrdersApi.reducer,
    [adminApi.reducerPath]: adminApi.reducer,
    [apiContainers.reducerPath]: apiContainers.reducer,
    [operationsApi.reducerPath]: operationsApi.reducer,
    [shipmentsApi.reducerPath]: shipmentsApi.reducer,
    [receptionsApi.reducerPath]: receptionsApi.reducer,
    [apiQr.reducerPath]: apiQr.reducer,
    [apiLerCodes.reducerPath]: apiLerCodes.reducer,
    [apiDeliveryCondition.reducerPath]: apiDeliveryCondition.reducer,
    [apiProcess.reducerPath]: apiProcess.reducer,
    users: userReducer,
    auth: authReducer,
    compositions: compositionsReducer,
    
  },
  middleware,
});

console.log(store.getState());

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
