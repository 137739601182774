import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'src/api/baseQuery';
import { Organization } from 'src/models/organization';

export interface GetOrganitzationByIdRequest {
  org_id: number;
}

export const apiOrganizations = createApi({
  // reducerPath: 'api_organizations',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    getAllOrganizations: builder.mutation<Organization[], void>({
      query: () => ({
        url: 'api/organizations/getAllOrg',
        method: 'GET',
      }),
    }),
    getOrganizationsById: builder.mutation<any, GetOrganitzationByIdRequest>({
      query: (GetOrganitzationByIdRequest) => ({
        url: `api/organizations/getById?org_id=${GetOrganitzationByIdRequest.org_id}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetAllOrganizationsMutation,
  useGetOrganizationsByIdMutation
} = apiOrganizations;
