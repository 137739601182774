import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import "./admin-organization-filters.css";
import useAdminOrganizationFilters from "./use-admin-organization-filters";

const AdminOrganizationFilters = () => {
  const {
    state: {
      organization_id,
      logistic_center_id,
      organizations,
      logisticCenters,
      can_change_logistic_center,
      can_change_organization,
    },
    actions: { handleChangeLogisticCenter, handleChangeOrganization },
  } = useAdminOrganizationFilters();

  return (
    <div className="admin-filters-container">
      <FormControl className="admin-filter">
        <InputLabel id="admin-organization-label">Organización</InputLabel>
        <Select
          labelId="admin-organization-label"
          id="admin-organization"
          value={(organizations.length && organization_id?.toString()) || ""}
          label="Organización"
          onChange={handleChangeOrganization}
          disabled={!can_change_organization}
        >
          {organizations.map((organization) => (
            <MenuItem
              key={`organization-${organization.id}`}
              value={organization.id.toString()}
            >
              {organization.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className="admin-filter">
        <InputLabel id="admin-center-label">Centro logistico</InputLabel>
        <Select
          labelId="admin-center-label"
          id="admin-center"
          value={
            (logisticCenters.length && logistic_center_id?.toString()) || ""
          }
          label="Centro logistico"
          onChange={handleChangeLogisticCenter}
          disabled={!can_change_logistic_center}
        >
          {logisticCenters.map((logisticCenter) => (
            <MenuItem
              key={`logistic-center-${logisticCenter.id}`}
              value={logisticCenter.id.toString()}
            >
              {logisticCenter.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default AdminOrganizationFilters;
