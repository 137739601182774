import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'src/api/baseQuery';
import { UserInfo } from 'src/models/userInfo';
import { User } from 'src/models/users';

export interface UserResponse {
  message: string;
  user: User;
}

export interface UserRequest {
  user_id: string;
  userInfo?: UserInfo;
}

export const apiUsers = createApi({
  reducerPath: 'api_user',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    getById: builder.mutation<UserResponse, UserRequest>({
      query: (UserRequest) => ({
        url: `api/users/get?user_id=${UserRequest.user_id}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetByIdMutation,
} = apiUsers;
