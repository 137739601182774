import { is } from 'date-fns/locale'
import { use } from 'i18next'
import { Navigate } from 'react-router-dom'
import AccessDenied from 'src/content/pages/AccessDenied'
import { useAppSelector } from 'src/hooks/store'
import { ROLE } from 'src/models/roles'

interface Props {
  component: React.ComponentType
  path?: string
  roles: Array<ROLE>
}

export const PrivateRoute: React.FC<Props> = ({ component: RouteComponent, roles }) => {
 
  const auth = localStorage.getItem('___reduxState__auth') ? JSON.parse(localStorage.getItem('___reduxState__auth') as string) : null
  const user = auth?.user
  const isAuthenticated = auth?.isAuthenticated
  const userHasRequiredRole = user?.role_type_permission.some(userRole => roles?.includes(userRole.role));
  
  if (isAuthenticated && userHasRequiredRole) {
    return <RouteComponent />
  }

  else if (isAuthenticated && !userHasRequiredRole) {
    return <AccessDenied />
  }

  return <Navigate to="/" />
}
