import { useEffect } from "react";
import { useGetLogisticCenterByOrgIdMutation } from "src/api/logisticCenters";
import { useGetAllOrganizationsMutation } from "src/api/organizations";
import { useUserContext } from "src/components/_shared/application-context/user-context/user-context";

const useAdminOrganizationFilters = () => {
	const [getOrganizations, getOrganizationsResult] = useGetAllOrganizationsMutation();
	const [getLogisticCenters, getLogisticCentersResult] = useGetLogisticCenterByOrgIdMutation();
	const {
    state: {
			user: { organization_id, logistic_center_id },
			flags: {
				can_change_logistic_center,
				can_change_organization,
			}
		},
		actions: {
			setLogisticCenter,
			setOrganization,
		}
  } = useUserContext();

	useEffect(() => {
		getOrganizations();
	}, []);

	useEffect(() => {
		if (!organization_id) return;
		getLogisticCenters(organization_id);
	}, [organization_id]);

	const handleChangeOrganization = (event) => {
		setOrganization(event.target.value);
	};

	const handleChangeLogisticCenter = (event) => {
		setLogisticCenter(event.target.value);
	};

	return {
		state: {
			organizations: getOrganizationsResult.data || [],
			logisticCenters: getLogisticCentersResult.data || [],
			organization_id,
			logistic_center_id,
			can_change_logistic_center,
			can_change_organization,
		},
		actions: {
			handleChangeLogisticCenter,
			handleChangeOrganization,
		}
	};
};

export default useAdminOrganizationFilters;