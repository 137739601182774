import { Box, Typography, Container, Button, Grid } from '@mui/material';
import { Helmet } from 'react-helmet-async';

import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const MainContent = styled(Box)(
	() => `
    height: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 15vh;
`,
);

function AccessDenied() {
	const { t } = useTranslation();
	return (
		<>
			<Helmet>
				<title>{t('accesDenied')}</title>
			</Helmet>
			<MainContent>
				<Grid
					xs={12}
					md={6}
					alignItems="center"
					display="flex"
					justifyContent="center"
					item
				>
					<Container maxWidth="sm">
						<Box textAlign="center">
							<img alt="500" height={260} src="/static/images/status/500.svg" />
							<Typography variant="h2" sx={{ my: 2 }}>
								{t('dontHavePermissions')}
							</Typography>
							<Typography
								variant="h4"
								color="text.secondary"
								fontWeight="normal"
								sx={{ mb: 4 }}
							>
								{t('contactAdmin')}
							</Typography>
							<Button
								data-testid="back-button"
								href="/management/users"
								variant="contained"
								sx={{ ml: 1 }}
							>
								Go back
							</Button>
						</Box>
					</Container>
				</Grid>
			</MainContent>
		</>
	);
}

export default AccessDenied;
