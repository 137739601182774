import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'src/api/baseQuery';
import { Colors } from 'src/models/colors';

export const apiColors = createApi({
  reducerPath: 'api_colors',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    getColors: builder.mutation<Colors[], void>({
      query: () => ({
        url: 'api/colors/getAll',
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetColorsMutation} = apiColors;