import { useRef, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  lighten,
  List,
  ListItemText,
  Popover,
  Typography,
  ListItemButton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import CorporateFareTwoToneIcon from '@mui/icons-material/CorporateFareTwoTone';
import { useTranslation } from 'react-i18next';
import {useAuthActions} from 'src/hooks/useAuthActions'
import { useAppSelector } from 'src/hooks/store';
import { useNavigate } from 'react-router-dom';
import { setSelectedUserOrganization } from 'src/store/users/userSlice'; 
import { useAppDispatch } from 'src/hooks/store';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`
);

function HeaderUserbox() {
  const dispatch = useAppDispatch()
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { authLogout } = useAuthActions()
  const user = useAppSelector((state) => state.auth.user)
  const selectedUserOrganization = useAppSelector((state) => state.users.selectedUserOrganization)
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = (): void => {
    authLogout();
  }

  const getInitials = (name: string, surname: string): string => {
    const initials = [];
    if (name) initials.push(name[0]);
    if (surname) initials.push(surname[0]);
    return initials.join('');
  };

  return (
    <>
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        <Avatar variant="rounded" alt={user?.name}>{getInitials(user?.name, user?.surname)}</Avatar>
        <Hidden mdDown>
          <UserBoxText>
          <UserBoxLabel variant="body1">{user?.name} {user?.surname}</UserBoxLabel>
            <UserBoxDescription variant="body2">
              {user?.email}
            </UserBoxDescription>
          </UserBoxText>
        </Hidden>
        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          <Avatar variant="rounded" alt={user?.name}>{getInitials(user?.name, user?.surname)}</Avatar>
          <UserBoxText>
            <UserBoxLabel variant="body1">{user?.name} {user?.surname}</UserBoxLabel>
            <UserBoxDescription variant="body2">{user?.email}</UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
        <List sx={{ p: 1 }} component="nav">
          <ListItemButton onClick={()=> navigate("/settings/userprofile")} sx={{ ml: 1 }} >
            <AccountBoxTwoToneIcon fontSize="small" sx={{ mr: 1 }} />
            <ListItemText primary={t('profile')} />
          </ListItemButton>
          <Divider sx={{ mt: 1 }} />
          <Typography variant="h5" mt={2} mb={1} ml={3} gutterBottom>
            {t('organizations')}
          </Typography>
          <ListItemButton 
            selected={selectedUserOrganization === user?.organization?.id} 
            onClick={()=> dispatch(setSelectedUserOrganization(user?.organization?.id))} 
            sx={{ ml: 1 }}
          >
            <CorporateFareTwoToneIcon fontSize="small" sx={{ mr: 1 }} />
            <ListItemText primary={user?.organization?.name} />
          </ListItemButton>
        </List>
        <Divider />
        <Box sx={{ m: 1 }}>
          <Button onClick={handleLogout} color="primary" fullWidth>
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            {t('signOut')}
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
