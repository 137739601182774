export enum ROLE {
  SUPER_ADMIN = 1,
  ADMIN = 2,
  TRACEABILITY = 3,
  SELLING = 4,
  SHIPPING = 5,
  STORE = 6,
  RECEPTION = 7,
  TRANSFORMATION = 8,
  CLASIFICATION = 9,
  MOBILE_APP = 10,
}

export enum ROLE_CREATE_USER {
  SUPER_ADMIN = 1,
  ADMIN = 2,
  USER = 3,
}

export enum PLAYER_TYPE_CREATE_USER {
  WAP = 4,
  WAM = 5,
}

export enum PLAYER_TYPE {
  TRA = 1,
  CERT = 2,
  CAR = 3,
  WAP = 4,
  WAM = 5,
  FIB = 6,
  SPI = 7,
  WEA = 8,
  WET = 9,
  GAR = 10,
  RET = 11,
  WAPGM = 12, //JSM 20240430
  WAPC = 13,
  PACK = 14,
  GEN = 15,
}
