import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'src/api/baseQuery';

export interface QrRequest {
  product_id: number;
}

export interface QrRequestContainer {
  container_id: number;
}

export interface QrRequestHopper {
  hopper_id: number;
}

export interface QrResponse {
  message: string;
  qr: string;
  reference: string;
}

export const apiQr = createApi({
  baseQuery: baseQueryWithReAuth,
  reducerPath: 'api_qr',
  endpoints: (builder) => ({
    getQr: builder.mutation<QrResponse, QrRequest>({
      query: (QrRequest) => ({
        url: 'api/qr/generate-qr-product?product_id=' + QrRequest.product_id,
        method: 'GET',
      }),
    }),
    getQrContainer: builder.mutation<QrResponse, QrRequestContainer>({
      query: (QrRequest) => ({
        url:
          'api/qr/generate-qr-container?container_id=' + QrRequest.container_id,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetQrMutation,
  useGetQrContainerMutation
} = apiQr;
