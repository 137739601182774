import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithReAuth } from "./baseQuery";

export interface SackRequest {
    product_id: number;
    container_id: number;
    weight?: number;
}

export interface SackResponse {
    remaining_waste: number;
}

export interface PickupRequest {
    estimated_plastic_packages: number;
    estimated_cardboard_packages: number;
    estimated_textil_packages: number;
}

export interface PlasticRegisterRequest {
    container_quantity: number;
    total_weight: number;
}

export interface CardboardRegisterRequest {
    container_quantity: number;
    total_weight: number;
}

export interface PlasticRegisterRequest {
    container_quantity: number;
    total_weight: number;
}

export interface CardboardRegisterRequest {
    container_quantity: number;
    total_weight: number;
}

export const operationsApi = createApi({
    baseQuery: baseQueryWithReAuth,
    reducerPath: 'operations',
    endpoints: (builder) => ({
        assignWasteOrderLineProductToContainer: builder.mutation<SackResponse, SackRequest>({
            query: body => ({
                url: 'api/operations/sack',
                method: 'POST',
                body
            }),
        }),
        createPickupShipment: builder.mutation<any, PickupRequest>({
            query: body => ({
                url: `api/operations/create-pickup-shipment`,
                method: 'POST',
                body
            })
        }),
        createPlasticContainer: builder.mutation<any, PlasticRegisterRequest>({
            query: body => ({
                url: `api/operations/create-plastic-container`,
                method: 'POST',
                body
            })
        }),
        createCardboardContainer: builder.mutation<any, CardboardRegisterRequest>({
            query: body => ({
                url: `api/operations/create-cardboard-container`,
                method: 'POST',
                body
            })
        }),
    }),
});

export const {
    useAssignWasteOrderLineProductToContainerMutation,
    useCreatePickupShipmentMutation,
    useCreatePlasticContainerMutation,
    useCreateCardboardContainerMutation,
} = operationsApi;